<script>
export default {
	lang: 'shop',
	props: {
		order: Object,
	},
}
</script>

<template>
	<div>
		<div class="d-flex align-center">
			<v-icon left small>mdi-email</v-icon>
			<b>Email</b>
		</div>
		<div>{{ order.user.accountEmail }}</div>
	</div>
</template>
