<script>
import brandsLogos from '../brands-logos.js'
export default {
	lang: 'shop',
	props: {
		order: Object,
		brand: Object,
		entity: Object,
		plan: Object,
		actionText: String,
		actionIcon: String,
	},
	computed: {
		brandsLogos() {
			return brandsLogos
		},
	},
}
</script>

<template>
	<div class="rounded-lg pa-3 d-flex flex-column" style="border: 1px solid #f1f1f1">
		<div class="d-flex flex-column flex-md-row align-md-center">
			<div class="d-flex align-start">
				<div class="flex-grow-0 mr-3">
					<div class="pa-2" style="border: 1px solid #ccc; border-radius: 4px">
						<Media :src="brandsLogos[brand.code]" :width="25" :height="25" />
					</div>
				</div>
				<div>
					<div>
						<b>{{ brand.name }}</b>
					</div>
					<div class="grey--text font-0">
						{{ entity.displayName }}
					</div>
				</div>
			</div>
			<div class="ml-0 ml-md-6 my-2 my-md-0">
				<div v-if="plan.specialPlanName || plan.specialPlanLogo" class="d-flex align-center">
					<Media v-if="plan.specialPlanLogo" width="80" height="30" :src="plan.specialPlanLogo" />
					<div
						v-if="plan.specialPlanName"
						class="font-0 grey--text text--darken-2 font-weight-bold"
						:class="{ 'ml-2': !!plan.specialPlanLogo }"
					>
						{{ plan.specialPlanName }}
					</div>
				</div>
				<div>
					<b class="nowrap d-flex align-center">
						<span class="mr-2"
							>{{ plan.qty
							}}{{ brand.type == 'debit' || plan.qty == 1 ? ' pago de ' : ' cuotas de' }}</span
						>
						<PriceText
							:amount="plan.installmentsAmount"
							:currency="order.currency.sign"
							:sup-decimals="false"
						/>
					</b>
				</div>
				<div class="success--text font-0 nowrap" v-if="plan.qty > 1 && !plan.surcharge">
					{{ 'Sin Interés' | lang }}
				</div>
				<div class="grey--text font-0 nowrap d-flex align-center" v-else-if="plan.qty > 1">
					<span class="mr-2">{{ 'Total' | lang }}</span>
					<PriceText :amount="plan.total" :currency="order.currency.sign" :sup-decimals="false" />
				</div>
			</div>
			<v-spacer v-if="!$b.m && actionText" />
			<div v-if="!$b.m && actionText">
				<Button color="link" text small @click.stop="$emit('click:action')">
					<v-icon v-if="actionIcon" class="mr-1" small>{{ actionIcon }}</v-icon> {{ actionText }}
				</Button>
			</div>
		</div>
		<div v-if="$b.m && actionText" class="pt-1">
			<Button block color="link" small @click.stop="$emit('click:action')">
				<v-icon v-if="actionIcon" class="mr-1" small>{{ actionIcon }}</v-icon
				>{{ actionText }}
			</Button>
		</div>
	</div>
</template>
