<script>
import modelBypassMixin from '@/utils/model-bypass-mixin'
import checkoutMixin from '@/v-shop/checkout/checkout-mixin'

export default {
	mixins: [modelBypassMixin(String, null), checkoutMixin],
	props: {
		selector: Object,
	},
	watch: {
		iValue(value) {
			this.$emit('canSubmit', !!value)
		},
	},
	created() {
		let payload = this.checkout.stepsData?.payment?.payload
		if (payload?.selection?.method.methodKey == 'CreditCard' && payload.input) {
			this.iValue = payload.input
		}
	},
}
</script>

<template>
	<CreditCardPlanSelector
		v-model="iValue"
		v-bind="{
			plansGroups: selector.checkoutData.plansGroups,
			currencySign: checkout.order.currency.sign,
			selectable: true,
		}"
	/>
</template>

<style></style>
