<script>
import checkoutMixin from '@/v-shop/checkout/checkout-mixin'
import { getRejectionError, getRejectionCodes } from './credit-card-rejection-error'
import { isComponent } from '@/v-shop/autoloader'

export default {
	lang: 'shop',
	mixins: [checkoutMixin],
	data() {
		return {
			rejectionCode: null,
			rejectionMessage: null,
			showRejectionMessage: false,
			paymentSubmitData: null,
			transactionHash: null,
			invalidPlan: false,
			formData: null,
			btnStatus: 'default',
		}
	},
	computed: {
		plan() {
			return this.checkout.stepViewData.plan
		},
		operatorKey() {
			return this.checkout.stepViewData.operatorKey
		},
		brand() {
			return this.plan.brand
		},
		entity() {
			return this.plan.entity
		},
		order() {
			return this.checkout.order
		},
		rejectionError() {
			return this.rejectionCode ? getRejectionError(this.rejectionCode, this.$lang) : null
		},
		formInputsComp() {
			let operatorComp = `CreditCard-OperatorFormInputs-${this.operatorKey}`
			let defaultComp = `CreditCardFormInputs`
			return isComponent(operatorComp) ? operatorComp : defaultComp
		},
	},
	methods: {
		async submit() {
			if (this.invalidPlan) {
				this.checkout.gotoStep('payment')
				return
			}

			if ((await this.checkout.beforeConfirm()) === false) return

			// this.checkout.loadingSubmit = true
			this.formData = null
			this.paymentSubmitData = null
			this.transactionHash = null
			this.rejectionCode = null
			this.rejectionMessage = null
			this.showRejectionMessage = false

			const formData = await this.$refs.form.onSubmit()
			if (formData === false || formData?.rejectionCode) {
				this.rejectionCode = formData?.rejectionCode || 'DEFAULT_ERROR'
				// this.checkout.loadingSubmit = false
				return
			}

			const payload = {
				brandCode: this.brand.code,
				entityCode: this.entity.code,
				planHash: this.plan.hash,
				ccbin: formData.ccbin || formData.number?.slice(0, 6) || null,
				lastFourDigits:
					formData.lastFourDigits || formData.number?.slice(formData.number.length - 4) || null,
				expirationMonth: formData.expirationMonth,
				expirationYear: formData.expirationYear,
				payerName: formData.payerName,
				payerIdNumber: formData.payerIdNumber,
				surcharge: this.plan.surcharge,
			}

			this.btnStatus = 'loading'

			const response = await this.checkout.reqWrapper(({ data, done }) => {
				data.payload = payload
				return this.$shopApi.post({
					url: `/credit-card/submit-payment`,
					data,
					loading: false,
					onMessage: ({ message, options }) => {
						if (getRejectionCodes().includes(message.code)) return
						options.defaults.onMessage({ message, options })
					},
					done,
				})
			})

			const { success, data, message, isCheckoutError } = response
			if (success) {
				this.formData = formData
				this.paymentSubmitData = data.paymentSubmitData
				this.transactionHash = data.transactionHash
			} else {
				this.btnStatus = 'error'
				if (isCheckoutError) return
				if (message.code == 'INVALID_PLAN' || message.code == 'PAYMENT_NOT_ALLOWED') {
					this.invalidPlan = true
				}
				this.rejectionCode = message.code || 'DEFAULT_ERROR'
			}
		},
		onResolverResponse(status, eventData) {
			let { orderId, rejectionCode, rejectionMessage } = eventData || {}
			this.btnStatus = status == 'approved' ? 'success' : 'error'
			switch (status) {
				case 'approved':
				case 'invalid':
					if (orderId) {
						window.location.href = this.$router.resolve({
							name: 'user.order-detail',
							params: { id: orderId },
						}).href
					} else {
						window.location.href = this.$router.resolve({ name: 'user.orders' }).href
					}
					break
				case 'rejected':
					this.rejectionCode = rejectionCode || 'DEFAULT_ERROR'
					this.rejectionMessage = rejectionMessage || null
					this.showRejectionMessage = false
					break
			}
		},
	},
	mounted() {
		const { query } = this.$route
		if (query.rejectionCode) {
			this.rejectionCode = query.rejectionCode
			this.rejectionMessage = query.rejectionMessage
			this.$router.replace({
				query: { ...query, rejectionCode: undefined, rejectionMessage: undefined },
			})
			this.$nextTick(() => {
				this.btnStatus = 'error'
				setTimeout(() => {
					this.$vuetify.goTo(this.$refs.rejectionMessageFocus, { duration: 0, offset: -60 })
					setTimeout(() => {
						this.btnStatus = 'default'
					}, 2000)
				}, 1000)
			})
		}
	},
}
</script>

<template>
	<div class="pb-4">
		<div class="font-weight-bold d-flex align-center">
			<v-icon>mdi-chevron-right</v-icon>
			<span>Completa los datos de tu tarjeta para realizar el pago</span>
		</div>
		<CreditCardPlanBox
			v-bind="{ order, brand, entity, plan }"
			action-text="Modificar"
			action-icon="mdi-pencil"
			@click:action="checkout.modifyStep('payment')"
			class="my-4"
		/>
		<div class="pr-sm-3 pb-3">
			<component
				:is="formInputsComp"
				ref="form"
				v-bind="{
					brand,
					entity,
					fixedPayerIdNumber: checkout.order.user.idNumber,
				}"
			/>
		</div>

		<div class="d-flex justify-end">
			<IndeterminateProgressBtn
				v-model="btnStatus"
				default-color="cta"
				success-color="success"
				error-color="error"
				@click:default="submit()"
				:error-duration="5000"
				block
				x-large
			>
				Realizar pago
				<template #success>
					<v-icon left> mdi-check </v-icon>
					Gracias por tu compra
				</template>
				<template #error>
					<v-icon left> mdi-close-thick </v-icon>
					No pudimos completar tu pago
				</template>
				<template #loading> Procesando pago... </template>
			</IndeterminateProgressBtn>
		</div>

		<div ref="rejectionMessageFocus" tabindex="-1"></div>
		<div v-if="rejectionError" class="pt-4" tabindex="-1">
			<v-alert class="ma-0" text type="error" icon="mdi-alert" border="left" elevation="2" dense>
				<div class="pl-2">
					<div class="font-2" v-if="rejectionError.title">
						<b>{{ rejectionError.title }}</b>
					</div>
					<div class="font-1 mt-2" v-if="rejectionError.text" style="white-space: pre-line">
						{{ rejectionError.text }}
					</div>
					<div class="font-1 mt-2" v-if="rejectionMessage">
						<a @click.prevent="showRejectionMessage = true"> Ver detalle </a>
						<div v-if="showRejectionMessage" style="opacity: 0.7">
							<i>{{ rejectionMessage }}</i>
						</div>
					</div>
				</div>
			</v-alert>
		</div>
		<!-- operator resolver -->
		<div v-if="paymentSubmitData && transactionHash">
			<component
				:is="`CreditCard-OperatorResolver-${operatorKey}`"
				v-bind="{
					paymentSubmitData,
					transactionHash,
					formData,
					brand,
					entity,
					plan,
					order,
				}"
				@rejected="onResolverResponse('rejected', $event)"
				@invalid="onResolverResponse('invalid', $event)"
				@approved="onResolverResponse('approved', $event)"
			/>
		</div>
	</div>
</template>

