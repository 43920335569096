<script>
import { VTextField } from 'vuetify/lib'
import inputMixin from '@/vuetify-mixins/input-mixin'

export default {
	name: 'TextField',
	props: {
		maskDisabled: [Boolean, Function],
		mask: Function,
	},
	extends: VTextField,
	mixins: [inputMixin],
	data() {
		return {
			inputElm: null,
			watching: true,
		}
	},

	watch: {
		value(text, oldText) {
			if (!this.watching || this.isMaskDisabled()) return

			text = String(text || '')
			let maskedText = this.mask(text)

			if (maskedText === false) {
				this.watching = false
				this.$nextTick(() => {
					let currPos = this.inputElm?.selectionStart || 0
					this.$emit('input', oldText)
					this.$nextTick(() => {
						let oldPos = currPos - (text.length - oldText.length)
						this.inputElm.selectionStart = oldPos
						this.inputElm.selectionEnd = oldPos
						this.watching = true
					})
				})
				return
			}

			maskedText = String(maskedText || '')
			let cursorPos = this.inputElm?.selectionStart || 0

			if (text == maskedText) {
				this.$nextTick(() => this.adjustCursorPosition({ cursorPos, text, maskedText }))
			} else {
				this.watching = false
				this.$nextTick(() => {
					this.$emit('input', maskedText)
					this.$nextTick(() => {
						this.adjustCursorPosition({ cursorPos, text, maskedText })
						this.watching = true
					})
				})
			}
		},
	},
	methods: {
		isMaskDisabled() {
			if (typeof this.maskDisabled == 'function') return !!this.maskDisabled(this.value)
			return !!this.maskDisabled
		},
		adjustCursorPosition({ cursorPos, text, maskedText }) {
			if (!this.inputElm) return

			let charsCount = (text.slice(0, cursorPos).match(/[a-z0-9]/gi) || []).length
			let adjustedPos
			for (let i = 0; i < maskedText.length; i++) {
				if (/[a-z0-9]/i.test(maskedText[i])) {
					charsCount--
					if (charsCount == 0) {
						adjustedPos = i + 1
						break
					}
				}
			}

			this.inputElm.selectionStart = adjustedPos
			this.inputElm.selectionEnd = adjustedPos
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.inputElm = this.$el.querySelector('input')
		})
	},
}
</script>
