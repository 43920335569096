<script>
import checkoutMixin from '../checkout-mixin'
import hooksMixin from '@/v-shop/mixins/hooks-mixin'

export default {
	mixins: [checkoutMixin, hooksMixin('CheckoutStepBox')],
	lang: 'shop',
	metaInfo() {
		if (this.state == 'active') {
			return { title: `${this.stepTitle} | Checkout` }
		}
	},
	props: {
		step: Object,
		allSteps: Array,
	},
	data() {
		return {
			pulse: false,
		}
	},
	computed: {
		isEditable() {
			return this.step.editable !== false
		},
		stepKey() {
			return this.step.key
		},
		stepData() {
			return this.checkout.stepsData?.[this.stepKey] || {}
		},
		stepIndex() {
			return this.allSteps.findIndex((x) => x.key == this.stepKey)
		},
		stepTitle() {
			return this.step.title
		},
		currentStepKey() {
			return this.$route.params.stepKey
		},
		currentStepIndex() {
			return this.allSteps.findIndex((x) => x.key == this.currentStepKey)
		},
		state() {
			if (this.checkout.firstLoading) return 'pending'
			if (this.checkout.loadingStep == this.stepKey) return 'loading'
			if (this.currentStepKey == this.stepKey) return 'active'
			if (this.stepData.done) return 'done'
			return 'pending'
		},
		showActiveContent() {
			return this.state == 'active'
		},
		showDoneContent() {
			return (
				this.state == 'done' &&
				this.checkout.calculatedUntil !== null &&
				this.checkout.calculatedUntil >= this.stepIndex &&
				this.currentStepIndex > this.stepIndex
			)
		},
		modifyDisabled() {
			return Boolean(this.checkout.loadingStep || this.checkout.loadingSubmit)
		},
		showStep() {
			if (this.checkout.style == 'multi') {
				return this.state == 'active' || this.state == 'loading' || this.currentStepKey == 'confirm'
			}
			return true
		},
	},
	watch: {
		state(value, oldValue) {
			this.pulse = this.checkout.style == 'single' && value == 'done' && oldValue == 'active'
		},
	},
	methods: {
		modifyStep() {
			this.checkout.modifyStep(this.stepKey)
		},
	},
}
</script>

<template>
	<v-card
		:class="[
			`state--${state}`,
			state === 'active' ? 'elevation-16' : 'elevation-1',
			$b.m ? 'rounded-0' : 'rounded-lg',
			'overflow-hidden',
			`checkout-style--${checkout.style}`,
			{ 'd-none': !showStep },
		]"
	>
		<!-- :class="{ 'elevation-1': state === 'active' }" -->
		<div class="d-flex align-center pa-4 header-container">
			<div class="circle-box success" :class="{ pulse }" v-if="state == 'done'">
				<v-icon :color="$vars.successtext">mdi-check</v-icon>
			</div>
			<div class="circle-box font-weight-bold font-2 black white--text" v-else-if="state == 'active'">
				{{ stepIndex + 1 }}
			</div>
			<div class="circle-box font-weight-bold font-2 black--text" v-else-if="state == 'pending'">
				{{ stepIndex + 1 }}
			</div>
			<div class="circle-box" v-else-if="state == 'loading'">
				<v-progress-circular color="black" size="28" indeterminate />
			</div>
			<div class="font-1 header ml-3">{{ stepTitle }}</div>
			<v-spacer />
			<Button
				text
				small
				color="link"
				@click="modifyStep"
				v-if="isEditable && showDoneContent && $b.td"
				:disabled="modifyDisabled"
			>
				<v-icon small class="mr-1">mdi-pencil</v-icon> Modificar
			</Button>
		</div>
		<div class="pa-6" v-if="showActiveContent">
			<hook :zone="`above-${stepKey}-active`" />
			<component :is="`CheckoutStep-${stepKey}--active`" />
			<hook :zone="`below-${stepKey}-active`" />
		</div>
		<div class="pa-6" v-if="showDoneContent">
			<hook :zone="`above-${stepKey}-done`" />
			<component :is="`CheckoutStep-${stepKey}--done`" :order="checkout.order" />
			<hook :zone="`below-${stepKey}-done`" />
		</div>
		<Button
			large
			color="purple darken-2"
			@click="modifyStep"
			v-if="isEditable && showDoneContent && $b.m"
			:disabled="modifyDisabled"
			block
			elevation="0"
			tile
		>
			<v-icon small class="mr-1">mdi-pencil</v-icon> Modificar
		</Button>
	</v-card>
</template>

<style lang="scss" scoped>
.v-card {
	&.state--pending {
		opacity: 0.5;
		pointer-events: none;
		::v-deep * {
			pointer-events: none;
		}
	}
	&.state--active {
		.header-container {
			position: sticky;
			top: 0;
		}
	}
	.header-container {
		background: white;
		z-index: 2;
		border-bottom: 1px solid #aaa;
	}
}
.circle-box {
	height: 28px;
	width: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	&.pulse {
		transform-origin: center center;
		animation: pulse 0.5s ease-in-out 2;
	}
}
@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.4);
	}
	100% {
		transform: scale(1);
	}
}
</style>

