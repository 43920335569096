<script>
import { isComponent } from '@/v-shop/autoloader'
export default {
	lang: 'shop',
	props: {
		order: Object,
	},
	computed: {
		payment() {
			return this.order.payments[0]
		},
		compName() {
			let name = `CheckoutStep-payment-${this.payment?.methodKey}--done`
			return isComponent(name) ? name : null
		},
	},
}
</script>

<template>
	<div v-if="payment">
		<div class="d-flex align-start" v-if="payment.methodKey == 'Void'">
			<v-icon left>mdi-currency-usd-off</v-icon>
			<span>No se requieren pagos en esta orden</span>
		</div>
		<template v-else>
			<div class="d-flex align-start">
				<Media :src="payment.optionImage" width="35" height="35" class="mr-3" />
				<div style="line-height: 35px">
					<b>{{ payment.optionTitle }}</b>
				</div>
			</div>
			<template v-if="payment.optionMessages">
				<div v-for="(item, i) of payment.optionMessages" :key="i" class="py-1 d-flex align-start">
					<v-icon class="mr-1 message-icon">mdi-chevron-right</v-icon>
					<div class="font-1">{{ item.text }}</div>
				</div>
			</template>
			<div v-if="compName">
				<component :is="compName" v-bind="{ order, payment }" />
			</div>
		</template>
	</div>
</template>

<style lang="scss" scoped>
.message-icon {
	position: relative;
	top: -1px;
}
</style>

