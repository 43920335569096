<script>
export default {
	lang: 'shop',
	props: {
		order: Object,
	},
}
</script>

<template>
	<div></div>
</template>
