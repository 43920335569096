<script>
import inputMixin from '@/vuetify-mixins/input-mixin'
import moment from 'moment'

export default {
	mixins: [inputMixin],
	props: {
		cleanable: Boolean,
		dialogProps: {
			type: Object,
			default: () => ({}),
		},
		pickerProps: {
			type: Object,
			default: () => ({}),
		},
		dateModel: String,
		placeholder: {
			type: String,
			default: 'AAAA-MM-DD',
		},
		dateTextFormat: {
			type: String,
			default: 'YYYY-MM-DD',
		},
		textfieldClass: String,
		textfieldAttrs: Object,
	},
	model: {
		prop: 'dateModel',
		event: 'changed',
	},
	data() {
		return {
			dialog: false,
			pickerDate: null,
			dateText: null,
		}
	},
	computed: {
		cTextfieldAttrs() {
			return {
				appendIcon: 'mdi-calendar',
				...(this.textfieldAttrs || {}),
			}
		},
	},
	methods: {
		onPickerAccept() {
			if (!this.pickerDate) return this.onPickerCleanup()
			this.dateText = moment(this.pickerDate).format(this.dateTextFormat)
			this.$emit('changed', this.pickerDate)
			this.dialog = false
		},
		onPickerCleanup() {
			this.dateText = null
			this.$emit('changed', null)
			this.pickerDate = null
			this.dialog = false
		},
		focus() {
			this.$refs.textField.focus()
		},
	},
	watch: {
		dateModel(value) {
			this.pickerDate = value
			if (!value) this.onPickerCleanup()
		},
		dialog(value) {
			if (value) {
				this.pickerDate = this.dateModel
			}
		},
		pickerDate(value) {
			if (value) this.onPickerAccept()
		},
	},
	created() {
		this.pickerDate = this.dateModel
	},
}
</script>

<template>
	<v-dialog v-model="dialog" persistent width="290px" v-bind="dialogProps">
		<template v-slot:activator="{ on, attrs }">
			<TextField
				style="padding-bottom: 1px"
				ref="textField"
				:value="dateText"
				:placeholder="placeholder"
				@click:append="dialog = true"
				readonly
				v-bind="{ ...attrs, ...$attrs, ...(cTextfieldAttrs || {}) }"
				v-on="{ ...on, ...$listeners }"
				:error-messages="validationErrors"
				:class="textfieldClass"
				@click:clear="onPickerCleanup"
			/>
		</template>
		<v-date-picker v-model="pickerDate" scrollable v-bind="pickerProps" :locale="$langIso || 'es-es'">
			<v-spacer></v-spacer>
			<Button text @click="onPickerCleanup" v-if="cleanable"> Borrar </Button>
			<Button text color="success" @click="onPickerAccept"> OK </Button>
		</v-date-picker>
	</v-dialog>
</template>

