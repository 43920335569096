<script>
export default {
	lang: 'shop',
	data() {
		return {
			isMounted: false,
			logo: this.$srv('CheckoutAppBar.logo'),
			backColor: this.$srv('CheckoutAppBar.backColor'),
			textColor: this.$srv('CheckoutAppBar.textColor'),
		}
	},
	methods: {
		keepBuying() {
			window.location.href = this.$router.resolve(this.$shop.getShopRoute()).href
		},
	},
	mounted() {
		this.isMounted = true
		this.$mt && window.toggleChat?.(false)
	},
	beforeDestroy() {
		this.$mt && window.toggleChat?.(true)
	},
}
</script>

<template>
	<div>
		<div :style="{ 'background-color': backColor, 'z-index': 1 }" class="py-4 elevation-1">
			<v-container class="d-flex align-center pa-0">
				<SafeLink to="/">
					<Media :src="logo" class="mr-2" />
				</SafeLink>
				<v-spacer />
				<Button text :color="textColor" small @click="keepBuying">
					<div class="preline">
						{{ $b.m ? $langText('Seguir\nComprando') : $lang('Seguir Comprando') }}
					</div>
				</Button>
			</v-container>
		</div>
		<v-main class="grey lighten-3" v-if="isMounted" style="min-height: calc(100vh - 72px)">
			<router-view />
		</v-main>
	</div>
</template>

