<script>
export default {
	lang: 'shop',
	props: {
		order: Object,
	},
}
</script>

<template>
	<div class="py-2">
		<div v-if="!order.deliveries.length" class="d-flex align-center">
			<v-icon left>mdi-truck-remove-outline</v-icon>
			<div class="font-1">{{ 'No aplican entregas para esta compra' | lang }}</div>
		</div>
		<div v-for="(delivery, i) of order.deliveries" :key="i" class="py-2">
			<div class="font-1 d-flex align-center pb-2" v-if="order.deliveries.length > 1">
				<v-icon color="link" left>mdi-storefront</v-icon>
				<div>
					Entrega de <b>{{ delivery.seller.name }}</b>
				</div>
			</div>
			<div style="border: 1px solid #bbb" class="pa-4 rounded-lg">
				<component
					:is="`DeliveryOptionContent-${delivery.methodKey}`"
					v-bind="{
						option: delivery,
						currency: order.currency.sign,
						showToAddressLine: true,
						showCost: true,
						showEta: true,
						showReceiver: !$srv('CheckoutOptions.disallowDeliveryReceiver'),
						showBeforePaymentMessages: true,
					}"
				/>
			</div>
		</div>
	</div>
</template>

