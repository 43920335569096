<script>
import checkoutMixin from '../checkout-mixin'
export default {
	mixins: [checkoutMixin],
	props: {
		value: Object,
		selected: Boolean,
	},
	data() {
		return {
			couponCode: this.value.couponCode,
		}
	},
	watch: {
		couponCode() {
			this.$emit('input', { couponCode: this.couponCode })
		},
	},
	mounted() {
		const { couponCode: queryCoupon } = this.checkout.initQuery || this.$route.query
		if (!this.value.couponCode && queryCoupon) {
			this.couponCode = queryCoupon
		}
	},
}
</script>

<template>
	<div>
		<div class="d-flex align-start">
			<v-icon class="mr-2">mdi-ticket-percent</v-icon>
			<b>Ingresa tu código de descuento</b>
		</div>
		<div v-if="selected" class="pt-4">
			<TextField
				v-model="couponCode"
				label="Código del cupón"
				placeholder="Ingresa el código del cupón"
				outlined
				hint="No acumulable con otras promociones"
				persistent-hint
				dense
			/>
		</div>
	</div>
</template>

