<script>
let makeMessagesArr = (messages) => {
	let arr = []
	if (messages) arr = Array.isArray(messages) ? messages : [messages]
	return arr.filter((msg) => !!msg)
}

export default {
	inject: {
		registerValidatorComponent: {
			default: null,
		},
		unregisterValidatorComponent: {
			default: null,
		},
	},
	props: {
		validatorKey: {
			type: String,
		},
	},
	data() {
		return {
			validatorId: null,
			validationErrors: [],
		}
	},
	created() {
		if (this.registerValidatorComponent) {
			this.validatorId = this.registerValidatorComponent?.(this)
		}
	},
	beforeDestroy() {
		if (this.validatorId) {
			this.unregisterValidatorComponent?.(this.validatorId)
		}
	},
	methods: {
		setValidation(errors) {
			this.validationErrors = makeMessagesArr(errors)
		},
		getValidatorKey() {
			return this.validatorKey
		},
	},
}
</script>

<template>
	<div class="my-1" v-if="validationErrors.length">
		<div
			v-for="(error, i) of validationErrors"
			:key="i"
			class="error white--text pa-2 my-1 font-weight-medium rounded font-1 d-flex align-center"
		>
			<v-icon color="white" class="mr-2" small> mdi-alert </v-icon>
			<div>{{ error }}</div>
		</div>
	</div>
</template>

<style></style>
