// import Worker from './worker'

export function useIndeterminateProgress() {
	const to = 100
	let status = 0
	let interval
	let velocity = 30
	let onUpdateStatusCallback
	let statusIncrementEvaluator

	const getIncrementFactor = (status) => {
		if (status < 30) {
			return 1
		} else if (status < 40) {
			return 0.7
		} else if (status < 50) {
			return 0.4
		} else if (status < 80) {
			return 0.3
		} else if (status < 90) {
			return 0.2
		} else {
			return Number(((to - status) / 100).toFixed(6))
		}
	}

	const getStatusIncrement = (status) => {
		const proximityToTarget = Math.abs(to - status) + 1
		const increment = 1 / Math.log(proximityToTarget)

		if (statusIncrementEvaluator) {
			return statusIncrementEvaluator(status, increment)
		}

		return increment * getIncrementFactor(status)
	}

	const setVelocity = (newVelocity) => {
		velocity = newVelocity
		startInterval()
	}

	const reset = () => {
		status = 0
		if (interval) clearInterval(interval)
	}

	const startInterval = () => {
		reset()
		interval = setInterval(() => {
			status += getStatusIncrement(status)
			onUpdateStatusCallback && onUpdateStatusCallback(status)
		}, velocity)
	}

	const finish = (cb) => {
		clearInterval(interval)

		interval = setInterval(() => {
			status += 5
			if (status >= to) {
				status = to
				interval && clearInterval(interval)
				cb && cb()
				interval = null
			}
			onUpdateStatusCallback && onUpdateStatusCallback(status)
		}, 20)
	}

	return {
		finish,
		start: startInterval,
		reset,
		getStatus: () => status,
		setVelocity,
		onProgress: (callback) => {
			onUpdateStatusCallback = callback
		},
		setStatusIncrementEvaluator: (callback) => {
			statusIncrementEvaluator = callback
		},
	}
}

// export function useIndeterminateProgressWorker(to = 100) {
// 	let onUpdateStatusCallback

// 	const worker = new Worker('./worker.js', { type: 'module' })
// 	worker.addEventListener('message', (event) => {
// 		const { action, payload } = event.data
// 		if (action === 'progress') {
// 			onUpdateStatusCallback && onUpdateStatusCallback(payload)
// 		}
// 	})

// 	const finish = () => {
// 		worker.postMessage({ action: 'finish' })
// 	}

// 	const start = () => {
// 		worker.postMessage({ action: 'start', to })
// 	}

// 	const reset = () => {
// 		worker.postMessage({ action: 'reset' })
// 	}

// 	}

// 	return {
// 		finish,
// 		start,
// 		reset,
// 		onProgress,
// 	}
// }
