<script>
export default {
	props: {
		logoSrc: String,
		icon: String,
		title: String,
	},
}
</script>

<template>
	<div>
		<div class="d-flex align-center">
			<div class="mr-3 pa-2 rounded" style="border: 1px solid #ccc" v-if="logoSrc || icon">
				<Media v-if="logoSrc" :src="logoSrc" contain :width="60" :height="30" />
				<v-icon v-else v-text="`mdi-${icon}`" style="width: 60px; height: 30px" />
			</div>
			<div class="font-weight-bold">{{ title }}</div>
		</div>
		<slot />
	</div>
</template>
