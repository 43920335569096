<script>
import { useIndeterminateProgress } from './useIndeterminateProgress'
export default {
	props: {
		defaultColor: {
			type: String,
			required: true,
		},
		successColor: {
			type: String,
			default: 'success',
		},
		loadingColor: {
			type: String,
		},
		errorColor: {
			type: String,
			default: 'error',
		},
		status: {
			type: String,
			default: 'default', // default, loading, success, error
		},
		errorDuration: Number,
		successDuration: Number,
		centerContent: Boolean,
		disableOverlay: Boolean,
		allowScroll: Boolean,
	},
	model: {
		prop: 'status',
		event: 'input',
	},
	data() {
		return {
			loadingValue: 0,
			progress: useIndeterminateProgress(),
			showLoading: false,
		}
	},
	created() {
		this.progress.onProgress((value) => {
			this.loadingValue = value
		})
	},
	computed: {
		progressBackgroundStyle() {
			return `transform: translateX(${this.loadingValue - 100}%);`
		},
		color() {
			return this[this.status + 'Color'] || this.defaultColor
		},
	},
	watch: {
		status(newStatus, oldStatus) {
			if (newStatus === 'loading') {
				if (!this.allowScroll) document.querySelector('html').style.overflow = 'hidden'
				this.showLoading = true
				this.progress.start()
			} else if (oldStatus === 'loading') {
				if (!this.allowScroll) document.querySelector('html').removeAttribute('style')
				this.progress.finish(() => {
					this.$nextTick(() => {
						setTimeout(() => {
							this.showLoading = false
						}, 200)
					})
				})
			}

			if (newStatus === 'error' && this.errorDuration) {
				setTimeout(() => {
					this.$emit('input', 'default')
				}, this.errorDuration)
			}

			if (newStatus === 'success' && this.successDuration) {
				setTimeout(() => {
					this.$emit('input', 'default')
				}, this.successDuration)
			}
		},
	},
	methods: {
		onClick() {
			this.$emit(`click:${this.status}`)
		},
	},
	beforeDestroy() {
		document.querySelector('html').removeAttribute('style')
	},
}
</script>
<!-- this.loadingValue === 0 ? 'transition: none' : 'transition: background-position 0.2s linear' -->
<template>
	<div class="relative w100">
		<transition name="fade">
			<div class="loading-overlay" v-if="showLoading && !disableOverlay"></div>
		</transition>
		<Button
			class="indeterminate-progress-btn"
			:style="{
				zIndex: showLoading ? 1005 : 0,
				pointerEvents: status === 'default' ? 'all' : 'none',
				transition: loadingValue === 0 ? 'transition: none' : 'transition: transform 0.2s linear',
			}"
			v-bind="$attrs"
			v-on="$listeners"
			:color="color"
			@click="onClick"
			:loading="false"
		>
			<transition name="fade">
				<div class="progress-background" v-if="showLoading" :style="progressBackgroundStyle"></div>
			</transition>
			<div class="relative">
				<component :is="$attrs.loading ? 'div' : 'transition'" name="fade" mode="out-in">
					<div :key="status" :class="{ 'd-flex justify-center align-center': centerContent }">
						<slot :name="status"></slot>
					</div>
				</component>
			</div>
		</Button>
	</div>
</template>

<style scoped lang="scss">
.loading-overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background: white;
	opacity: 0.8;
}
.indeterminate-progress-btn {
	// background-size: 200% 100%;
	position: relative;
	overflow: hidden;
}

::v-deep .v-btn__content {
	position: unset !important;
}
.progress-background {
	background-color: rgba(0, 0, 0, 0.4);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.relative {
	position: relative;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
