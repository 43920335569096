<script>
export default {
	props: {
		items: Array,
	},
}
</script>

<template>
	<div class="delivery-seller-items-box">
		<table cellspacing="0">
			<tbody>
				<tr v-for="(item, i) of items" :key="i">
					<td>
						<Media :src="item.image" width="35px" height="35px" img-class="rounded" />
					</td>
					<td class="w100">
						<div class="font-1">{{ item.name }}</div>
						<div v-if="item.variantName" class="grey--text font-0">
							{{ item.variantName }}
						</div>
					</td>
					<td class="font-1 font-weight-bold">x{{ item.qty }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<style lang="scss" scoped>
.delivery-seller-items-box {
	table {
		tr:not(:last-child) td {
			border-bottom: 1px solid #ddd;
		}
		tr td {
			padding: 8px;
		}
	}
}
</style>
