<script>
import checkoutMixin from '../checkout-mixin'
export default {
	lang: 'shop',
	mixins: [checkoutMixin],
	data() {
		return {
			dialog: false,
		}
	},
	computed: {
		order() {
			return this.checkout.order
		},
	},
	methods: {
		toggleDialog() {
			this.dialog = !this.dialog
		},
	},
}
</script>

<template>
	<div v-if="order">
		<div @click="toggleDialog" class="bottom-bar elevation-8" :style="{ 'z-index': dialog ? 1001 : 3 }">
			<div v-if="!dialog">
				<div class="d-flex justify-space-between align-center pa-3">
					<span class="font-weight-bold">{{ 'TOTAL' | lang }}</span>
					<PriceText :amount="order.total" class="font-5" :currency="order.currency.sign" />
				</div>
				<Button block tile large color="link">
					{{ 'Ver resumen' | lang }} <v-icon class="ml-1">mdi-chevron-up</v-icon>
				</Button>
			</div>
			<Button block tile large v-else>
				{{ 'Cerrar' | lang }} <v-icon class="ml-1">mdi-chevron-down</v-icon>
			</Button>
		</div>
		<v-dialog
			v-model="dialog"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
			scrollable
			class="white"
		>
			<CheckoutSummary />
		</v-dialog>
	</div>
</template>

<style scoped>
.bottom-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #fff;
	cursor: pointer;
	border-top: 1px solid #ddd;
}
.v-card {
	box-shadow: none !important;
}
.checkout-summary ::v-deep .v-card {
	height: calc(100vh - 44px);
	overflow: auto;
}
</style>

