<script>
import modelBypassMixin from '@/utils/model-bypass-mixin'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import uniq from 'lodash/uniq'
import checkoutMixin from '../checkout-mixin'

export default {
	lang: 'shop',
	mixins: [modelBypassMixin([Object]), checkoutMixin],
	props: {
		seller: Object,
		options: Object,
		items: Array,
		singleSeller: Boolean,
		address: Object,
	},
	data() {
		return {
			pickupPointsDialog: false,
			selectablePickupPointOption: null,
			removeLoading: false,
			activePickupPointsOptions: null,
			activePickupPointsGroupName: null,
		}
	},
	watch: {
		iValue() {
			if (this.iValue?.methodKey == 'PickupPoint') {
				this.selectablePickupPointOption = this.iValue
			}
		},
	},
	computed: {
		hasAnyOption() {
			return Object.values(this.options).flat().length > 0
		},
		shippingOptions() {
			return sortBy(this.options.Shipping, ['total', 'optionName'])
		},
		pickupPointsOptions() {
			return sortBy(this.options.PickupPoint, [
				'total',
				(x) => (x.toAddress?.zipcode?.code == this.address.zipcode.code ? -1 : 1),
				'optionName',
			])
		},
		pickupPointsOptionsGroups() {
			let groups = groupBy(this.pickupPointsOptions, (x) => x.checkoutGroup || 'Puntos de entrega')
			return Object.entries(groups).map(([name, options]) => {
				options.forEach((x) => (x.checkoutGroup = x.checkoutGroup || name))
				let uniqPrices = uniq(options.map((x) => x.total))
				let price = Math.min(...uniqPrices)
				let from = price > 0 && uniqPrices.length > 1
				return { name, options, from, price }
			})
		},
	},
	methods: {
		async removeCartProducts() {
			this.removeLoading = true
			for (let item of this.items) {
				await this.$shop.deleteCartItem({
					cartItemId: item.cartItemId,
					loading: false,
				})
			}
			this.$emit('items-removed')
			this.removeLoading = false
		},
		openPickupPointsDialog(groupName, groupedOptions) {
			this.pickupPointsDialog = true
			this.activePickupPointsGroupName = groupName
			this.activePickupPointsOptions = groupedOptions
		},
	},
	mounted() {
		let allOptions = [...this.shippingOptions, ...this.pickupPointsOptions]

		this.iValue =
			allOptions.find(({ hash }) => hash == this.iValue?.hash) ||
			this.shippingOptions[0] ||
			this.pickupPointsOptions[0] ||
			null

		this.selectablePickupPointOption =
			this.iValue?.methodKey == 'PickupPoint'
				? this.iValue
				: this.pickupPointsOptions.length == 1
				? this.pickupPointsOptions[0]
				: null
	},
}
</script>

<template>
	<div class="delivery-seller-box">
		<div class="font-1 d-flex align-center" v-if="!singleSeller">
			<v-icon color="link" left>mdi-storefront</v-icon>
			<div>
				Productos de <b>{{ seller.name }}</b>
			</div>
		</div>
		<div class="delivery-options-box">
			<!-- order items -->
			<CheckoutDeliverySellerOrderItemsBox :items="items" v-if="!singleSeller" />

			<!-- delivery options -->
			<div class="delivery-options-items-box" v-if="hasAnyOption">
				<v-radio-group v-model="iValue" class="font-1 ma-0 pa-0" hide-details>
					<CheckoutDeliveryOptionItem
						v-for="(option, i) of options.Shipping"
						:key="i"
						:selected="iValue == option"
						:option="option"
						@click="iValue = option"
					/>

					<template v-for="(group, i) of pickupPointsOptionsGroups">
						<CheckoutDeliveryOptionItem
							:key="`opener_${i}`"
							@click="openPickupPointsDialog(group.name, group.options)"
							class="d-flex align-center link--text"
						>
							<v-icon color="link" class="mr-2">mdi-map-marker-outline</v-icon>
							<div>{{ group.name }}</div>
							<v-spacer />
							<template
								v-if="
									!selectablePickupPointOption ||
									selectablePickupPointOption.checkoutGroup != group.name
								"
							>
								<div v-if="group.from" class="mr-2 grey--text">Desde</div>
								<div v-if="group.price > 0">
									<PriceText
										:amount="group.price"
										:currency="checkout.order.currency.sign"
										class="font-2 font-weight-bold"
									/>
								</div>
								<div v-else>
									<div class="rounded white--text success d-inline-block px-2 font-1">
										{{ 'Gratis' | lang }}
									</div>
								</div>
							</template>
							<!-- <div v-else class="grey--text">Ver todos</div> -->
							<v-icon large>mdi-chevron-right</v-icon>
						</CheckoutDeliveryOptionItem>
						<CheckoutDeliveryOptionItem
							:key="`selected_${i}`"
							v-if="
								selectablePickupPointOption && selectablePickupPointOption.checkoutGroup == group.name
							"
							:selected="iValue == selectablePickupPointOption"
							:option="selectablePickupPointOption"
							@click="iValue = selectablePickupPointOption"
						/>
					</template>
				</v-radio-group>
			</div>

			<div v-else class="pa-4 no-delivery-options-box d-flex align-center justify-space-between">
				<div class="error--text mr-6 font-weight-bold font-0 d-flex align-center">
					<v-icon left color="error">mdi-emoticon-sad-outline</v-icon>
					<span>
						Lo sentimos, no hay opciones disponibles para entregarte estos productos en tu zona.
					</span>
				</div>
				<Button color="error" small @click="removeCartProducts" :loading="removeLoading">
					<v-icon left small>mdi-cart-remove</v-icon> Quitar productos
				</Button>
			</div>
		</div>
		<CheckoutDeliveryPickupPointsSelectorDialog
			v-model="pickupPointsDialog"
			:seller="seller"
			:options="activePickupPointsOptions"
			:title="activePickupPointsGroupName"
			:current-selection="iValue"
			@accept="iValue = $event"
		/>
	</div>
</template>
<style lang="scss" scoped>
.delivery-options-box {
	border-radius: 8px;
	border: 1px solid #ccc;
	margin: 12px 0px 24px;
	overflow: hidden;
	.delivery-seller-items-box {
		padding: 12px 20px;
	}
	.no-delivery-options-box {
		background-color: #ddd;
	}
}
</style>

