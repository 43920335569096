<script>
import { isComponent } from '@/v-shop/autoloader'
import checkoutMixin from '../checkout-mixin'

export default {
	lang: 'shop',
	mixins: [checkoutMixin],
	computed: {
		confirmPaymentComp() {
			let methodKey = this.checkout.order.payments[0].methodKey
			let name = `CheckoutStep-confirm-${methodKey}--active`
			return isComponent(name) ? name : null
		},
	},
}
</script>

<template>
	<component class="confirm-payment" v-if="confirmPaymentComp" :is="confirmPaymentComp" />
</template>
<style scoped>
.confirm-payment ::v-deep .bottom-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	cursor: pointer;
}
</style>
