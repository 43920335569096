<script>
import { VSelect } from 'vuetify/lib'
import inputMixin from '@/vuetify-mixins/input-mixin'

export default {
	name: 'Select',
	extends: VSelect,
	mixins: [inputMixin],
}
</script>
