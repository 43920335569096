var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g(_vm._b({staticClass:"delivery-option-item",class:{
		'delivery-option-item--selected': _vm.selected,
		'delivery-option-item--selectable': !!_vm.option,
	}},'div',_vm.$attrs,false),_vm.$listeners),[(_vm.option)?_c('div',{staticClass:"d-flex align-start"},[_c('v-radio',{attrs:{"value":_vm.option}}),_c(("DeliveryOptionContent-" + (_vm.option.methodKey)),_vm._b({tag:"component",staticClass:"w100"},'component',{
				option: _vm.option,
				currency: _vm.checkout.order.currency.sign,
				showCost: true,
				showEta: true,
				showBeforePaymentMessages: true,
			},false))],1):_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }