<script>
import addressHelperMixin from '@/v-shop/mixins/address-helper-mixin.js'

export default {
	lang: 'shop',
	mixins: [addressHelperMixin],
	props: {
		order: Object,
	},
	computed: {
		buyer() {
			return this.order.buyer
		},
		invoice() {
			return this.order.invoice
		},
		invoiceAddress() {
			return this.order.invoiceAddress
		},
	},
}
</script>

<template>
	<v-row>
		<v-col cols="12">
			<div>{{ buyer.firstname }} {{ buyer.lastname }}</div>
			<div>DNI {{ buyer.idNumber }}</div>
			<div>{{ buyer.fullPhone }}</div>
			<div>{{ buyer.email }}</div>
		</v-col>
		<v-col cols="12">
			<div class="font-weight-bold">
				{{ 'Domicilio de facturación' | lang }}
			</div>
			<div>{{ makeAddressLine(invoiceAddress) }}</div>
		</v-col>
		<v-col cols="12" v-if="invoice.business">
			<div class="font-weight-bold">{{ 'Factura' | lang }} {{ invoice.invoiceType }}</div>
			<div>{{ invoice.businessName }}</div>
			<div>CUIT {{ invoice.businessIdNumber }}</div>
		</v-col>
	</v-row>
</template>
