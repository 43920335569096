<script>
import { get } from 'vuex-pathify'
import checkoutMixin from '@/v-shop/checkout/checkout-mixin'

export default {
	mixins: [checkoutMixin],
	data() {
		return {
			activeForm: 'signup',
		}
	},
	computed: {
		user: get('shop/user'),
	},
	watch: {
		user(value) {
			if (value) this.checkout.stepSubmit()
		},
	},
}
</script>

<template>
	<LoginForms v-model="activeForm" />
</template>
