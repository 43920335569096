<script>
import { isComponent } from '@/v-shop/autoloader'
import checkoutMixin from '../checkout-mixin'

export default {
	lang: 'shop',
	mixins: [checkoutMixin],
	data() {
		return {
			selection: null,
			canSubmit: false,
			selectionInput: null,
		}
	},
	watch: {
		selection: {
			deep: true,
			handler(value) {
				this.selectionInput = null
				this.canSubmit = value ? !this.compExists(value) : false
			},
		},
	},
	computed: {
		paymentSelectors() {
			return this.checkout.stepViewData.paymentSelectors || []
		},
	},
	methods: {
		compName(selector) {
			return `CheckoutStep-payment-${selector.method.methodKey}--active`
		},
		compExists(selector) {
			return isComponent(this.compName(selector))
		},
		submit() {
			if (!this.canSubmit) return
			this.checkout.stepSubmit({
				selection: this.selection,
				input: this.selectionInput,
			})
		},
	},
	mounted() {
		let payload = this.checkout.stepsData?.payment?.payload
		let selection = null
		if (payload?.selection) {
			selection = this.paymentSelectors.find((x) => x.paymentOptionId == payload.selection.paymentOptionId)
		}

		if (!selection && this.paymentSelectors.length == 1) {
			selection = this.paymentSelectors[0]
		}

		this.selection = selection
	},
}
</script>

<template>
	<div v-if="paymentSelectors.length">
		<div class="payment-options-box">
			<v-radio-group v-model="selection" class="font-1 ma-0 pa-0" hide-details>
				<div v-for="(selector, i) of paymentSelectors" :key="i" class="payment-option-box">
					<!-- payment selector -->
					<div
						class="payment-option-item d-flex align-start payment-option-item--selectable"
						:class="{ 'payment-option-item--selected': selection == selector }"
						@click="selection = selector"
					>
						<v-radio :value="selector" />
						<div class="w100">
							<div class="d-flex align-center">
								<Media :src="selector.image" width="35" aspect-ratio="1" />
								<div class="font-weight-bold font-1 pl-2">
									{{ selector.title }}
								</div>
							</div>
							<div v-for="(item, i) of selector.messages" :key="i" class="py-1 d-flex align-start">
								<v-icon class="mr-1 message-icon">mdi-chevron-right</v-icon>
								<div class="font-1">{{ item.text }}</div>
							</div>
						</div>
					</div>
				</div>
			</v-radio-group>
		</div>
		<div v-if="selection && compExists(selection)">
			<component
				:is="compName(selection)"
				:selector="selection"
				v-model="selectionInput"
				@canSubmit="canSubmit = $event"
			/>
		</div>
		<div class="d-flex justify-end pt-4">
			<Button :disabled="!canSubmit" @click="submit()" color="success" :block="$b.m" :large="$b.m">
				Siguiente
			</Button>
		</div>
	</div>
	<div v-else>
		<div
			class="px-4 py-2 rounded no-payment-options-box d-flex flex-wrap align-center justify-center justify-sm-space-between"
		>
			<div class="error--text font-weight-bold font-0 d-flex align-center">
				<v-icon left color="error">mdi-emoticon-sad-outline</v-icon>
				<span> Lo sentimos, no hay opciones de pago disponibles para estos productos. </span>
			</div>
		</div>
		<div class="pt-3 text-center text-sm-left">
			<Button color="link" small :to="$shop.getShopRoute()">
				<span class="nowrap">Volver a la tienda</span>
			</Button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.payment-options-box {
	border-radius: 8px;
	border: 1px solid #ccc;
	border-top: none;
	margin: 12px 0px 24px;
	overflow: hidden;
}
.no-payment-options-box {
	background-color: #eee;
}
.payment-option-item {
	.v-radio {
		position: relative;
		top: 5px;
		margin-bottom: 0 !important;
	}
	background-color: #fff;
	padding: 12px 16px 12px 12px;
	border-left: 4px solid #ccc;
	cursor: pointer;
	border-top: 1px solid #bbb;
	&:hover {
		background-color: #f9f9f9;
		border-left: 4px solid #aaa;
	}
	&--selected {
		background-color: #f5f5f5 !important;
		border-left: 4px solid var(--link) !important;
	}
	.message-icon {
		position: relative;
		top: -1px;
	}
}
</style>

