<script>
export default {
	props: {
		order: Object,
		payment: Object,
	},
}
</script>

<template>
	<CreditCardPlanBox
		class="mt-2"
		:brand="payment.data.brand"
		:entity="payment.data.entity"
		:plan="payment.data.plan"
		:order="order"
	/>
</template>

<style></style>
