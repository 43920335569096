<script>
import { get } from 'vuex-pathify'
import addressHelperMixin from '@/v-shop/mixins/address-helper-mixin.js'
import checkoutMixin from '../checkout-mixin'

export default {
	lang: 'shop',
	mixins: [addressHelperMixin, checkoutMixin],
	data() {
		return {
			buyer: {},
			// invoiceId: null,
			// invoices: [],
			invoice: {},
			invoiceAddressLine: null,
			invoiceAddress: null,
			addressDialog: false,
		}
	},
	computed: {
		user() {
			return this.checkout.order.user
		},
		/*asCard() {
			return this.invoices.length > 0
		},*/
		countriesFilter() {
			return (country) => country.iso == 'AR'
		},
	},
	watch: {
		'invoice.business'() {
			this.$nextTick(() => {
				this.checkout.validation = { ...this.checkout.validation }
			})
		},
		invoiceAddress(value) {
			if (value) this.invoiceAddressLine = this.makeAddressLine(value)
		},
	},
	methods: {
		submit() {
			return this.checkout.stepSubmit({
				buyer: this.buyer,
				invoice: this.invoice,
				invoiceAddress: this.invoiceAddress,
			})
		},
		setInvoiceAddress(address) {
			this.invoiceAddress = {
				...address,
				intersection1: null,
				intersection2: null,
				comment: null,
			}
		},
	},
	mounted() {
		const payload = this.checkout.stepsData.contact.payload || {}
		const viewData = this.checkout.stepViewData

		this.buyer = payload.buyer || {
			idNumber: this.user.idNumber,
			firstname: this.user.firstname,
			lastname: this.user.lastname,
			phoneCountryId: this.user.phoneCountryId,
			phoneAreaCode: this.user.phoneAreaCode,
			phoneNumber: this.user.phoneNumber,
		}

		let availablePhoneCountries = viewData.countryPhones
		if (availablePhoneCountries.length) {
			let currId = this.buyer.phoneCountryId
			let buyerPhoneCountryIsAvailable = currId && availablePhoneCountries.some(({ id }) => id == currId)
			if (!buyerPhoneCountryIsAvailable) {
				this.buyer.phoneCountryId = availablePhoneCountries[0].id
				this.buyer.phoneAreaCode = null
				this.buyer.phoneNumber = null
			}
		} else if (!this.buyer.phoneCountryId && viewData.invoicingCountry) {
			this.buyer.phoneCountryId = viewData.invoicingCountry.id
		}

		if (viewData.invoiceAddress) {
			if (viewData.invoicingCountry) {
				if (viewData.invoiceAddress.zipcode.state.countryId == viewData.invoicingCountry.id) {
					this.setInvoiceAddress(viewData.invoiceAddress)
				}
			} else {
				this.setInvoiceAddress(viewData.invoiceAddress)
			}
		}

		this.invoice = viewData.invoice || {}

		/* this.invoices = (viewData.invoices || []).filter((invoice) => invoice.invoiceType != 'E')
		if (payload.invoiceId && this.invoices.find(({ id }) => id == payload.invoiceId)) {
			this.invoiceId = payload.invoiceId
		} else if (this.invoices.length) {
			this.invoiceId = this.invoices[0].id
		} else {
			this.invoice = {
				business: false,
				personFirstname: this.user.firstname,
				personLastname: this.user.lastname,
			}
		}*/
	},
}
</script>

<template>
	<div>
		<Validator :validation="checkout.validation">
			<form @submit.prevent="submit()">
				<v-row>
					<v-col cols="12" md="4">
						<TextField
							:label="$lang('Nombre')"
							v-model="buyer.firstname"
							:disabled="user.fixedIdentityFields"
						/>
					</v-col>
					<v-col cols="12" md="4">
						<TextField
							:label="$lang('Apellido')"
							v-model="buyer.lastname"
							:disabled="user.fixedIdentityFields"
						/>
					</v-col>
					<v-col cols="12" md="4">
						<TextField
							label="Nro. de documento"
							v-model="buyer.idNumber"
							:disabled="user.fixedIdentityFields"
						/>
					</v-col>
					<v-col cols="12" md="6">
						<CountryPhoneCodeSelector
							:countryPhones="checkout.stepViewData.countryPhones"
							v-model="buyer.phoneCountryId"
						/>
					</v-col>
					<v-col cols="4" md="2">
						<TextField :label="$lang('Cód. Área')" type="tel" v-model="buyer.phoneAreaCode" />
					</v-col>
					<v-col cols="8" md="4">
						<TextField
							:label="$lang('Teléfono')"
							placeholder="11111111"
							type="tel"
							v-model="buyer.phoneNumber"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<TextArea
							:label="$lang('Domicilio de facturación')"
							:placeholder="$lang('Seleccionar...')"
							rows="1"
							auto-grow
							readonly
							no-resize
							v-model="invoiceAddressLine"
							@click="addressDialog = true"
							validator-key="invoiceAddress"
							class="invoice-address-textarea"
						/>
						<div class="text-right mt-2">
							<Button color="link" small outlined @click="addressDialog = true">
								{{ 'Seleccionar domicilio' | lang }}
							</Button>
						</div>
					</v-col>
				</v-row>
				<v-checkbox v-model="invoice.business">
					<template #label>
						<span class="font-weight-bold font-1">
							{{ 'Soy una empresa' | lang }}
						</span>
					</template>
				</v-checkbox>
				<v-row v-if="invoice.business">
					<v-col cols="12" md="8">
						<TextField :label="$lang('Razón Social')" v-model="invoice.businessName" />
					</v-col>
					<v-col cols="12" md="4">
						<TextField label="CUIT" placeholder="XX-XXXXXXXX-X" v-model="invoice.businessIdNumber" />
					</v-col>
					<v-col cols="12">
						<v-radio-group
							v-model="invoice.invoiceType"
							row
							mandatory
							hide-details="auto"
							:error-messages="checkout.validation['invoice.invoiceType']"
						>
							<v-radio :label="$lang('Factura A')" value="A" />
							<v-radio :label="$lang('Factura B')" value="B" />
						</v-radio-group>
					</v-col>
				</v-row>
				<div class="d-flex pt-6">
					<v-spacer v-show="!$b.m" />
					<Button
						@click="submit()"
						color="success"
						:loading="checkout.loadingSubmit"
						:block="$b.m"
						:large="$b.m"
					>
						{{ 'Continuar' | lang }}
					</Button>
				</div>
			</form>
		</Validator>
		<AddressDialog
			v-model="addressDialog"
			@addressSelected="setInvoiceAddress($event)"
			:title="$lang('Selecciona el domicilio de facturación')"
			:available-country="checkout.stepViewData.invoicingCountry"
			hide-delivery-fields
		/>
	</div>
</template>

<style scoped>
.invoice-address-textarea ::v-deep textarea {
	line-height: 1.1;
	padding-top: 10px;
	padding-bottom: 10px;
	cursor: pointer;
}
</style>

