<script>
import moment from 'moment'

export default {
	lang: 'shop',
	props: {
		brand: Object,
		entity: Object,
		fixedPayerIdNumber: String,
	},
	data() {
		return {
			rules: {
				required: () => (value) => {
					value = String(value || '').trim()
					return value != '' ? true : this.$lang('Requerido')
				},
				maxLen: (max) => (value) => {
					value = String(value || '').trim()
					return value.length <= max ? true : this.$lang(`Máximo {max} caracteres`, { max })
				},
				numLen: (len) => (value) => {
					value = String(value || '').trim()
					return value.length == len ? true : this.$lang(`Ingresa {len} dígitos`, { len })
				},
				ccnumber: () => (value) => {
					value = String(value || '').replace(/\s+/g, '')
					if (!this.brand || !value) return true
					if (!value.match(/^[0-9]+$/)) return this.$lang('Ingresa solo números')
					if (!value.match(this.brand.pattern)) {
						return this.$lang(`El número no corresponde a una Tarjeta {brand}`, {
							brand: this.brand.name,
						})
					}
					return true
				},
				ccnumLen: () => (value) => {
					value = String(value || '').replace(/\s+/g, '')
					if (this.brand.ccnumLen.includes('-')) {
						let [from, to] = this.brand.ccnumLen.split('-')
						if (value.length >= Number(from) && value.length <= Number(to)) return true
						return this.$lang(
							`El número de tu Tarjeta {brand} debe tener entre {from} y {to} dígitos`,
							{
								brand: this.brand.name,
								from,
								to,
							}
						)
					} else {
						if (value.length == Number(this.brand.ccnumLen)) return true
						return this.$lang(`El número de tu Tarjeta {brand} debe tener {len} dígitos`, {
							brand: this.brand.name,
							len: this.brand.ccnumLen,
						})
					}
				},
				dni: () => (value) => {
					value = String(value || '').trim()
					if (!value) return true
					return value.match(/^[1-9]{1}[0-9]{6,7}$/) ? true : this.$lang('El DNI es inválido')
				},
				payerName: () => (value) => {
					value = String(value || '').trim()
					if (!value) return true
					return value.match(/^[a-záéíóúñ]+(\s+[a-záéíóúñ]+)+$/i) ? true : this.$lang('Nombre inválido')
				},
				numeric: () => (value) => {
					value = String(value || '').trim()
					if (!value) return true
					return value.match(/^[0-9]+$/) ? true : this.$lang('Ingresa solo números')
				},
				month: () => (value) => {
					let m = String(value || '').trim()
					return m.match(/^(0[1-9]{1}|1[0-2]{1})$/) ? true : this.$lang('Mes inválido')
				},
				year: () => (value) => {
					let y = String(value || '').trim()
					if (!y.match(/^(2[0-9]{3}|[0-9]{2})$/)) {
						return this.$lang('Año inválido')
					}
					y = Number(y)
					if (y >= 2000) y -= 2000
					let thisYear = Number(moment().format('YY'))
					return y >= thisYear && y <= thisYear + 50 ? true : this.$lang('Año inválido')
				},
			},
			form: {},
			validForm: false,
		}
	},
	computed: {
		cvvPlaceholder() {
			if (!this.brand) return ''
			return ''.padStart(this.brand.cvvLen, 'X')
		},
		creditCardNumberPlaceholder() {
			if (!this.brand) return ''
			return ''.padStart(
				this.brand.ccnumLen.includes('-') ? this.brand.ccnumLen.split('-')[0] : this.brand.ccnumLen,
				'X'
			)
		},
	},
	watch: {
		validForm(value) {
			this.$emit('can-submit', value)
		},
	},
	methods: {
		normalizePayerName() {
			return this.form.payerName
				.trim()
				.replace(/\s+/g, ' ')
				.toUpperCase()
				.replace(/Á/g, 'A')
				.replace(/É/g, 'E')
				.replace(/Í/g, 'I')
				.replace(/Ó/g, 'O')
				.replace(/Ú/g, 'U')
		},
		normalizeExpirationYear() {
			let expirationYear = Number(this.form.expirationYear)
			if (expirationYear > 2000) expirationYear -= 2000
			return String(expirationYear).trim()
		},
		onSubmit() {
			if (this.$refs.form.validate()) {
				return {
					number: this.form.number.replace(/\s+/g, ''),
					expirationMonth: this.form.expirationMonth.trim(),
					expirationYear: this.normalizeExpirationYear(),
					payerName: this.normalizePayerName(),
					payerIdNumber: this.form.payerIdNumber.trim(),
					cvv: this.form.cvv.trim(),
				}
			} else {
				return { rejectionCode: 'VALIDATION_ERROR' }
			}
		},
		setDefaults() {
			this.$assign(this.form, {
				number: '',
				payerName: '',
				payerIdNumber: this.fixedPayerIdNumber,
				expirationMonth: '',
				expirationYear: '',
				cvv: '',
			})
		},
		onlyNumbers(text) {
			return text.replace(/[^0-9]/g, '')
		},
		/*monthYearFilter(text) {
			text = this.onlyNumbers(text)
			let month = text.slice(0, 2)
			let year = text.slice(2, 4)
			let year2 = text.slice(4, 6)
			if (year2.length == 2 && year == '20') {
				year = year2
			} else if (year2) {
				return false
			}

			if (!year) return month
			return `${month}/${year}`
		},*/
		formatCardNumber(text) {
			text = this.onlyNumbers(text)
			if (!text) return ''

			if (this.brand.ccnumLen) {
				if (text.length > this.brand.ccnumLen) {
					return false
				}
				let spl = this.brand.ccnumLen.split('-')
				let max = parseInt(spl[1] || spl[0])
				text = text.slice(0, max)
			}

			let formatedValue = ''
			if (this.brand?.numberFormat) {
				let c = 0
				for (let n of this.brand.numberFormat.split('-')) {
					n = parseInt(n)
					let p = text.slice(c, c + n)
					if (p) formatedValue += p + '  '
					c += n
				}
			} else {
				formatedValue = text
			}

			return formatedValue.trim()
		},
	},
	async created() {
		this.setDefaults()
		this.formatCardNumber(String(this.form.number || ''))
	},
}
</script>

<template>
	<v-form ref="form">
		<v-container :style="{ maxWidth: $b({ smAndUp: 480 }) }" :fluid="$b({ xs: true })">
			<v-row dense>
				<v-col cols="12">
					<MaskedTextField
						id="card-number"
						class="card-number"
						:outlined="true"
						type="tel"
						v-model="form.number"
						dense
						:rules="[rules.required(), rules.ccnumber(), rules.ccnumLen()]"
						:label="$lang('Número de Tarjeta')"
						:hide-details="false"
						prepend-icon="mdi-credit-card"
						validate-on-blur
						:mask="(text) => formatCardNumber(text)"
					/>
				</v-col>
				<v-col cols="12" sm="8">
					<TextField
						id="card-holder-name"
						class="card-holder-name"
						:outlined="true"
						v-model="form.payerName"
						dense
						:rules="[rules.required(), rules.maxLen(30), rules.payerName()]"
						:label="$lang('Nombre impreso en la Tarjeta')"
						:placeholder="$lang('JUAN A PEREZ')"
						maxlength="30"
						:hide-details="false"
						prepend-icon="mdi-account"
						validate-on-blur
					/>
				</v-col>
				<v-col cols="12" sm="4">
					<MaskedTextField
						id="card-holder-id"
						class="card-holder-id"
						type="tel"
						:outlined="true"
						dense
						v-model="form.payerIdNumber"
						:rules="[rules.required(), rules.numeric(), rules.dni()]"
						ref="test"
						:label="$lang('DNI del titular')"
						:disabled="!!fixedPayerIdNumber"
						placeholder="22333444"
						maxlength="8"
						:hide-details="false"
						validate-on-blur
						prepend-icon="mdi-card-account-details-outline"
						:mask="(x) => onlyNumbers(x)"
					/>
				</v-col>
				<v-col cols="6" sm="3">
					<MaskedTextField
						id="card-expiration-month"
						class="card-expiration-month"
						type="tel"
						:outlined="true"
						dense
						v-model="form.expirationMonth"
						:rules="[rules.required(), rules.numeric(), rules.month()]"
						:label="$lang('Mes')"
						:placeholder="$lang('MM')"
						maxlength="2"
						ref="expMonth"
						:hide-details="false"
						prepend-icon="mdi-calendar-month"
						validate-on-blur
						:mask="(x) => onlyNumbers(x)"
					/>
				</v-col>
				<v-col cols="6" sm="3">
					<MaskedTextField
						id="card-expiration-year"
						class="card-expiration-year"
						type="tel"
						:outlined="true"
						dense
						v-model="form.expirationYear"
						:rules="[rules.required(), rules.numeric(), rules.year()]"
						:label="$lang('Año')"
						:placeholder="$lang('AA')"
						maxlength="4"
						:hide-details="false"
						validate-on-blur
						:mask="(x) => onlyNumbers(x)"
					/>
				</v-col>
				<v-col cols="12" sm="6">
					<MaskedTextField
						id="card-cvc"
						class="card-cvc"
						type="tel"
						:outlined="true"
						dense
						v-model="form.cvv"
						:rules="[rules.required(), rules.numeric(), rules.numLen(brand.cvvLen)]"
						:label="$lang('CVV')"
						:placeholder="cvvPlaceholder"
						:maxlength="brand.cvvLen"
						:hide-details="false"
						prepend-icon="mdi-credit-card-lock-outline"
						validate-on-blur
						:mask="(x) => onlyNumbers(x)"
					/>
				</v-col>
			</v-row>
		</v-container>
	</v-form>
</template>

<style scoped>
.v-text-field ::v-deep .v-messages__message {
	font-size: 13px;
	color: var(--error);
	padding: 4px 6px;
	border-radius: 6px;
	display: inline-block;
}

.v-text-field ::v-deep .v-text-field__details {
	padding: 0 !important;
}

.card-number ::v-deep input {
	letter-spacing: 2px;
}
.card-holder-name ::v-deep input {
	text-transform: uppercase;
}
</style>
