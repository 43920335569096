<script>
import modelBypassMixin from '@/utils/model-bypass-mixin'
export default {
	mixins: [modelBypassMixin([Boolean], {})],
	props: {
		value: Boolean,
		seller: Object,
		options: Array,
		title: String,
		currentSelection: Object,
	},
	data() {
		return {
			selection: null,
		}
	},
	watch: {
		iValue(value) {
			if (value) {
				if (this.currentSelection?.methodKey == 'PickupPoint') {
					this.selection = this.currentSelection
				} else {
					this.selection = this.options[0]
				}
			}
		},
	},
	methods: {
		accept() {
			if (!this.selection) return
			this.$emit('accept', this.selection)
			this.iValue = false
		},
	},
}
</script>

<template>
	<v-dialog v-model="iValue" max-width="600px" scrollable>
		<v-card>
			<v-card-title class="d-flex align-start flex-nowrap">
				<div class="flex-shrink-1">
					<div class="d-flex align-center">
						<v-icon>mdi-map-marker</v-icon>
						<!-- <div>
							Puntos de entrega de <b>{{ seller.name }}</b>
						</div> -->
						<div>{{ title }}</div>
					</div>
					<div class="font-1">Seleccioná el punto de entrega donde querés retirar los productos</div>
				</div>
				<v-spacer />
				<Button icon @click="iValue = false">
					<v-icon>mdi-close</v-icon>
				</Button>
			</v-card-title>
			<v-card-text>
				<div class="py-2">
					<v-radio-group v-model="selection" class="font-1 ma-0 pa-0" hide-details>
						<CheckoutDeliveryOptionItem
							v-for="(option, i) of options"
							:key="i"
							:option="option"
							selectable
							:selected="selection == option"
							@click="selection = option"
						/>
					</v-radio-group>
				</div>
			</v-card-text>
			<v-card-actions class="justify-end">
				<Button text class="mr-4" @click="iValue = false">Cancelar</Button>
				<Button color="success" @click="accept()">Aceptar</Button>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style></style>
