<script>
import checkoutMixin from '../checkout-mixin'
export default {
	mixins: [checkoutMixin],
	props: {
		option: Object,
		selected: Boolean,
	},
}
</script>
<template>
	<div
		class="delivery-option-item"
		:class="{
			'delivery-option-item--selected': selected,
			'delivery-option-item--selectable': !!option,
		}"
		v-bind="$attrs"
		v-on="$listeners"
	>
		<div class="d-flex align-start" v-if="option">
			<v-radio :value="option" />
			<component
				class="w100"
				:is="`DeliveryOptionContent-${option.methodKey}`"
				v-bind="{
					option,
					currency: checkout.order.currency.sign,
					showCost: true,
					showEta: true,
					showBeforePaymentMessages: true,
				}"
			/>
		</div>
		<slot v-else />
	</div>
</template>

<style lang="scss" scoped>
.delivery-option-item {
	background-color: #fff;
	padding: 16px 16px 16px 12px;
	cursor: pointer;
	border-top: 1px solid #bbb;

	&:hover {
		background-color: #f9f9f9;
	}

	&--selectable {
		border-left: 4px solid #ccc;
		&:hover {
			border-left: 4px solid #aaa;
		}
	}

	&--selected {
		background-color: #f5f5f5 !important;
		border-left: 4px solid var(--link) !important;
	}

	.v-radio {
		margin-bottom: 0 !important;
	}
}
</style>
