var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2"},[(!_vm.order.deliveries.length)?_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-truck-remove-outline")]),_c('div',{staticClass:"font-1"},[_vm._v(_vm._s(_vm._f("lang")('No aplican entregas para esta compra')))])],1):_vm._e(),_vm._l((_vm.order.deliveries),function(delivery,i){return _c('div',{key:i,staticClass:"py-2"},[(_vm.order.deliveries.length > 1)?_c('div',{staticClass:"font-1 d-flex align-center pb-2"},[_c('v-icon',{attrs:{"color":"link","left":""}},[_vm._v("mdi-storefront")]),_c('div',[_vm._v(" Entrega de "),_c('b',[_vm._v(_vm._s(delivery.seller.name))])])],1):_vm._e(),_c('div',{staticClass:"pa-4 rounded-lg",staticStyle:{"border":"1px solid #bbb"}},[_c(("DeliveryOptionContent-" + (delivery.methodKey)),_vm._b({tag:"component"},'component',{
					option: delivery,
					currency: _vm.order.currency.sign,
					showToAddressLine: true,
					showCost: true,
					showEta: true,
					showReceiver: !_vm.$srv('CheckoutOptions.disallowDeliveryReceiver'),
					showBeforePaymentMessages: true,
				},false))],1)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }